import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/Rules.css";

function Rules() {
  return (
    <>
      <Navbar />
      <div className="main__Rules">
        <br />
        <br />
        <br />
        <h1>REGLES DES JEUX</h1>
        <br />
        <br />
        <div className="container">
          <span> ARTICLE 1 - ORGANISATEUR</span>
          <br />
          <span>
          La société ISO APPS , société par actions simplifiée au capital de 100  euros,
          immatriculée au RCS de Paris sous le numéro 948 782 420 dont le siège social
          est situé  66 Rue de la convention 75015 Paris  (Ci-après « ISO APPS ») 
          édite une application pour smartphone et tablette (Ci-après « l’Application »).
          </span>
          <br />
          <br />
          <span>
          Via cette application, ISO APPS organise « win and win », un jeu gratuit et 
          sans obligation d’achats (Ci-après « Le Jeu »).
          </span>
          <br />
          <br />
          <span>
          Toutes les marques, logos et autres signes distinctifs reproduits sur l’Application
          sont la propriété exclusive de ISO APPS ou de ses partenaires. Ils sont protégés à 
          ce titre par les dispositions du Code de la propriété intellectuelle. Toute 
          reproduction non autorisée de ces marques, logos et signes constitue une contrefaçon 
          passible de sanctions pénales.
          </span>
          <br />
          <br />
          <span>
          Il est précisé qu’Apple n’est pas un sponsor et n’est aucunement impliqué dans les lots 
          offerts. Les lots n’ont aucun lien avec les produits Apple.
          </span>
          <br />
          <br />
          <h3>ARTICLE 2 - CONDITIONS DE PARTICIPATION - INSCRIPTION</h3>
          <br />
          <span>
          2.1 Conditions de participation
          </span>
          <br />
          <br />
          <span>
          Toute personne physique, majeure, à l’exception du personnel de ISO APPS et 
          de leur famille, peut s’inscrire et jouer sur l’Application (ci-après, le « Participant »).
          Toute personne inscrite sur l’Application reconnaît avoir pris connaissance du présent règlement 
          et s’engage à le respecter dans son intégralité.
          </span>
          <br />
          <br />
          <span>
          2.2 Inscription
          </span>
          <br />
          <br />
          <span>            
          Aucune inscription n’est nécessaire pour participer au Jeu.
          </span>
          <br />
          <br />
          <span>
          Chaque Participant sera identifié grâce à son smartphone ou à sa tablette dont il reconnaît 
          être l’utilisateur habituel.
          </span>
          <br />
          <br />
          <h3>ARTICLE 3 - DESCRIPTION DU JEU</h3>
          <br />
          <br />
          <span>
          Le Jeu consiste pour chaque Participant à choisir quatre cartes une de chaque type , 
          c’est-à-dire à sélectionner et enregistrer, dans une grille composée de 52 cartes :
          </span>
          <br />
          <br />
          <span>
          après avoir visionné un contenu publicitaire.
          </span>
          <br />
          <br />
          <span>
          Une fois la sélection enregistrée, le joueur doit “l’envoyer” pour qu'elle soit prise 
          en compte par l’application lors du prochain tirage. Un bouton est prévu à cet effet à 
          plusieurs endroits de l’application 
          </span>
          <br />
          <br />
          <span>
          Le tirage consiste en un tirage au sort de 1 carte parmi les 13 de chaque type c’est à 
          dire 4 cartes parmi les 52. Les combinaisons gagnantes sont tirées au sort. Chaque participant 
          ayant sélectionné une des combinaisons gagnantes obtient le lot le plus élevé associé aux numéros 
          et signes qu’il a enregistrés.
          </span>
          <br />
          <br />
          <span>
          Par exemple, un Participant ayant enregistré 3 cartes  corrects :
          </span>
          <br />
          <br />
          <span>
          obtiendra uniquement le lot correspondant à la combinaison «Trois cartes» ; et n’obtiendra ni le 
          lot correspondant à la combinaison « Deux cartes » ni celui correspondant à la combinaison « une carte ».
          </span>
          <br />
          <br />
          <span>Il existe quatre types de tirages sur win and win : PIÈCES, BILLET, LIASSE,PLEIN AUX  AS.</span>
          <br />
          <br />
          <span>Pour jouer sur un type de tirage, le joueur doit sélectionner le tirage qui veut via les icon prévu à cet effet.
          </span>
          <br />
          <br />
          <span>
          Par exemple, s’il veut jouer sur le tirage ”Plein Aux AS”, il doit avoir atteint un certain niveau d'expérience pour y participer”.
          </span>
          <br />
          <br />
          <h3>ARTICLE 4 - DEROULEMENT DU JEU</h3>
          <br />
          <span>
          Le Participant ouvre l’Application et choix et sélectionne quatre cartes Il visionne ensuite un contenu publicitaire, 
          par exemple une vidéo. S’il s’agit d’une vidéo, elle ne pourra pas excéder 3 minutes. Une fois le contenu publicitaire visualisé, la sélection choisie par le Participant est validée.
          </span>
          <br />
          <br />
          <span>
          Le participant peut jouer du lundi au dimanche. Le Participant peut jouer autant de fois qu’il le souhaite dans la 
          limite de 100 sélections par tirage.
          </span>
          <br />
          <br />
          <h3>ARTICLE 5 - TIRAGE</h3>
          <br />
          <span>
          Les horaires des différents tirages sont disponibles sur l'application.
          </span>
          <br />
          <br />
          <span>
          Les résultats d’un tirage sont connus 1 heure maximum après son déroulement.
          </span>
          <br />
          <br />
          <span>
          Le tirage du niveau “PIÈCES” et “BILLET” est effectué  par un algorithme aléatoire autonome sécurisé
          </span>
          <br />
          <br />
          <h3>ARTICLE 6 - COMBINAISONS</h3>
          <br />
          <span>
          Dès que le résultat du tirage est connu, le caractère gagnant d’une combinaison est défini.
          L’ordre dans lequel les numéros figurent dans une combinaison est indifférent.
          </span>
          <br />
          <br />
          <span>
          Les combinaisons gagnantes sont les suivantes :
          </span>
          <br />
          <br />
          <span>
          1ère Combinaison :   4 numéro et 4 signes
          </span>
          <br />
          <br />
          <span>
          3ème Combinaison : 4 numéro et  2 signes
          </span>
          <br />
          <br />
          <span>
          4ème Combinaison : 4 numéro et 1 signes
          </span>
          <br />
          <br />
          <span>
          5ème Combinaison : 4 numéro et  0 signes
          </span>
          <br />
          <br />
          <span>
          6ème Combinaison : 3 numéro et 3 signes
          </span>
          <br />
          <br />
          <span>
          7ème Combinaison : 3 numéro et 2 signes
          </span>
          <br />
          <br />
          <span>
          8ème Combinaison : 3 numéro et 1 signes
          </span>
          <br />
          <br />
          <span>
          9ème Combinaison :  3 numéro et 0 signes
          </span>
          <br />
          <br />
          <span>
          10ème Combinaison : 2 numéro et 2 signes
          </span>
          <br />
          <br />
          <span>
          Chaque combinaison gagnante est associée à un lot décrit dans la page 
          « COMMENT GAGNER» de l’Application.
          </span>
          <br />
          <br />
          <h3>ARTICLE 7 – RÈGLES APPLICABLES AUX LOTS</h3>
          <br />
          <span>
          7.1 Règles applicables au Jackpot (cf « la 1 ère Combinaison »)
          </span>
          <br />
          <br />
          <span>
          7.1.1 En cas de combinaison gagnante
          </span>
          <br />
          <br />
          <span>
          7.1.1.1 En cas de gagnant unique
          </span>
          <br />
          <br />
          <span>
          Dans l’hypothèse où un seul participant a enregistré la 1ère Combinaison, la totalité de la somme du Jackpot lui est allouée.
          </span>
          <br />
          <br />
          <span>
          Il est précisé que si ce participant a enregistré plusieurs fois la 1ère Combinaison, pour le même tirage, 
          il n’obtiendra qu’une seule fois le Jackpot.
          </span>
          <br />
          <br />
          <span>
          7.1.1.2 En cas de pluralité de gagnants
          </span>
          <br />
          <br />
          <span>
          Dans l’hypothèse où plusieurs Participants ont enregistré la 1ère Combinaison, pour le même tirage, chaque 
          combinaison gagnante permettra à chaque Participant de remporter une quote-part du Jackpot.
          </span>
          <br />
          <br />
          <span>
          - Si X Participants ont enregistré la 1ère Combinaison (enregistrée à chaque fois une fois), chaque Participant 
          obtiendra 1/X du Jackpot. Par exemple : si 3 Participants ont enregistré la 1ère Combinaison, chaque Participant 
          obtiendra 1/3 du Jackpot.
          </span>
          <br />
          <br />
          <span>
          - Si un ou plusieurs Participants ont enregistré plusieurs fois la 1ère Combinaison, la quote-part du Jackpot allouée 
          à chaque Participant sera calculée au prorata du nombre de 1ères combinaisons enregistrées par chaque Participant. 
          Par exemple : si 2 Participants ont enregistré la 1ère Combinaison, et si le 1er Participant ne l’a enregistré qu’une 
          seule fois, alors que le 2e Participant l’a enregistré 4 fois, le 1er Participant obtiendra 1/5 du Jackpot tandis que 
          le 2e Participant obtiendra 4/5 du Jackpot.
          </span>
          <br />
          <br />
          <span>
          Les lots (ou les quotes-parts de lots) sont arrondis au dollars supérieur.
          </span>
          <br />
          <br />
          <span>
          7.2. Offre du jour
          </span>
          <br />
          <br />
          <span>
          Lorsque le gain proposé est de type "bonus quotidien" (signalé par une section spécifique dans le menu « bonus quotidien »),
          le rang de chaque participant est susceptible de varier chaque jour après chaque tirage. Le lot à gagner est déterminé à 23:59:59
           HEUR GMT chaque jour .
          </span>
          <br />
          <br />
          <span>
          7.2.1 prix offre bonus quotidien
          </span>
          <br />
          <br />
          <span>
          le joueur avec le nombre de “XP” Le plus élevé obtiendra un lot de 30 USD
          </span>
          <br />
          <br />
          <span>
          le deuxième obtiendra 25 USD; le troisième obtiendra 20 USD le quatrième obtiendra 15 USD
          </span>
          <br />
          <br />
          <span>
          le cinquième obtiendra 10 USD
          </span>
          <br />
          <br />
          <span>
          Et du sixième au dixième obtient l'équivalence en jetons waw
          </span>
          <br />
          <br />
          <span>
          7.3 paiement de lots en cash
          </span>
          <br />
          <br />
          <span>
          WIN AND WIN adressera aux gagnants d’un montant supérieur ou égal à 6 000€, leur gain par échéances égales pendant 
          12 mois (il est précisé que la première échéance sera versée dans les 45 jours ouvrés à compter du tirage concerné).
          </span>
          <br />
          <br />
          <span>
          WIN AND WIN adressera aux gagnants d‘un montant supérieur ou égal à 10 000€, leur gain par échéances égales pendant 
          24 mois (il est précisé que la première échéance sera versée dans les 45 jours ouvrés à compter du tirage concerné).
          </span>
          <br />
          <br />
          <span>
          Les autres gains seront versés en une seule fois.
          </span>
          <br />
          <br />
          <span>
          7.4 Opérations Spéciales
          </span>
          <br />
          <br />
          <span>
          win and win peut proposer différents challenges sur lesquels le participant peut gagner de nombreux cadeaux 
          (tickets, cash, cartes cadeaux, etc…)
          </span>
          <br />
          <br />
          <span>
          Les récompenses dépendent de chaque challenge organisé. Lors de ces opérations spéciales, le fonctionnement de chaque 
          challenge est disponible sur les interstitiels via l’application, sur les réseaux sociaux et sur le site internet 
          https://www.winandwin.app
          </span>
          <br />
          <br />
          <span>
          Dans le cas de récompenses en CASH, se reporter à l’article 9 REMISE DE LOTS.
          </span>
          <br />
          <br />
          <h3>ARTICLE 8 - PUBLICATION DES RÉSULTATS</h3>
          <br />
          <span>
          Les résultats des tirages de win and win sont portés à la connaissance du public, via l'application. ou sur les reseaux sociaux
          </span>          
          <br />
          <br />
          <h3>ARTICLE 9 – REMISE DES LOTS</h3>
          <br />
          <span>
          9.1 Règles générales
          </span>
          <br />
          <br />
          <span>
          Pour recevoir les lots, le gagnant doit avoir rempli son profil joueur dans l’application : nom, prénom, adresse de 
          résidence, date de naissance, adresse électronique,numéro de téléphone.
          </span>
          <br />
          <br />
          <span>
          Pour recevoir son virement, il sera redirigé vers notre outil de paiement sur lequel les informations suivantes lui 
          seront demandées : les informations à remplir dans l’outil de paiement : photographie du RIB et d’une pièce d’identité 
          du gagnant (carte d’identité, permis de conduire, passeport), IBAN, et photographie et/ou vidéo témoignage de lui-même 
          avec le droit de les exploiter sur tous les supports win and win  (L'application, Facebook, Instagram, etc...).
          </span>
          <br />
          <br />
          <span>
          La société WIN AND WIN pourra également exiger une vidéo témoignage du gagnant pour les montants supérieurs ou égaux à 100€.
          </span>
          <br />
          <br />
          <span>
          En cas de demande de confidentialité, la société WIN AND WIN proposera de flouter l’image du gagnant.
          </span>
          <br />
          <br />
          <span>
          Toutes ces informations sont obligatoires et doivent être fournies dans un délai de 30 jours à compter de la date du 
          gain afin de pouvoir vérifier l’identité du gagnant et effectuer le virement bancaire.
          </span>
          <br />
          <br />
          <span>
          Le paiement se fera après vérification de l’identité du gagnant et du respect des clauses de ce règlement. En cas de 
          triche ou tentative de triche (utilisation d’un bloqueur de publicité par exemple), WIN AND WIN se réserve le droit 
          d’invalider le gain.
          </span>
          <br />
          <br />
          <span>
          Les gagnants reçoivent une confirmation de lots en recevant un « push » via l’Application.
          </span>
          <br />
          <br />
          <span>
          Tout participant ayant gagné un lot en cash de 500€ ou plus sera contacté directement par téléphone et par mail par 
          l’équipe win and win afin de lui détailler les étapes nécessaires au bon déroulement du paiement du gain.
          </span>
          <br />
          <br />
          <span>
          Tout Participant ayant gagné un lot en cash en dessous de 500€ devra se faire connaître auprès de l’équipe win and 
          win en remplissant son profil via l’outil de paiement afin de fournir les pièces justificatives détaillées ci-dessus.
          </span>
          <br />
          <br />
          <span>
          Dans tous les cas, si le gain n’est pas réclamé dans les délais impartis, le gagnant en perdrait définitivement 
          le bénéfice sans recours possible. De ce fait, aucune réclamation ne sera acceptée.
          </span>
          <br />
          <br />
          <span>
          9.2 Bons d’achat et Bons cadeaux
          </span>
          <br />
          <br />
          <span>
          WIN AND WIN adressera aux gagnants leur lot (bons d'achat avec ou sans condition selon les rangs), au plus tard 
          24 heures à compter du tirage concerné.
          </span>
          <br />
          <br />
          <span>
          Les gagnants recevront le Bon correspondant à leur lot via l’Application.
          </span>
          <br />
          <br />
          <span>
          Ce Bon devra nécessairement être utilisé dans les 30 jours de son obtention. A défaut, les Bons qui n’auront 
          pas été utilisés dans ce délai seront définitivement perdus et non remis en jeu.
          </span>
          <br />
          <br />
          <span>
          Par exception, certains Bons devront être utilisés dans un délai plus court. Ces délais seront indiqués sur 
          l’Application, dans l’onglet «Gift shop». Ce délai prime sur celui prévu dans le présent règlement.
          </span>
          <br />
          <br />
          <span>
          Par ailleurs, il est précisé qu’il ne peut être utilisé qu’un Bon d’achat par paiement.
          </span>
          <br />
          <br />
          <span>
          Ainsi, si un gagnant dispose de deux Bons d’achat, il devra les affecter à deux paiements différents.
          </span>
          <br />
          <br />
          <h3>ARTICLE 10 - STORE DES LOTS</h3>
          <br />
          <span>
          Le joueur peut obtenir des Points waw lors des tirages, en réalisant des combinaisons gagnantes à certains rangs.
          </span>
          <br />
          <br />
          <span>
          Les Points waw ( XP) obtenus par le joueur suite aux tirages peuvent être utilisés dans le GIFT SHOP » et 
          convertis en lots : packs de tickets et bons d’achat.
          </span>
          <br />
          <br />
          <span>
          On entend par « bon d’achat » un bon permettant de bénéficier d’une réduction sous condition d’un minimum d’achats.
          </span>
          <br />
          <br />
          <span>
          Les Points waw ( XP) sont cumulables, tirage après tirage. Le joueur peut les utiliser au moment où il le souhaite, 
          dans la mesure où il a suffisamment de Points waw pour obtenir le lot de son choix.
          </span>
          <br />
          <br />
          <span>
          Les photographies ou représentations graphiques représentant les Bons ont exclusivement une fonction d’illustration 
          et ne sont pas contractuelles.
          </span>
          <br />
          <br />
          <span>
          En cas d’impossibilité de fournir un Bon identique à celui présenté sur l’Application durant l’organisation du Jeu, 
          WIN AND WIN se réserve le droit de substituer un cadeau équivalent, sans que le gagnant ne puisse évoquer un quelconque préjudice.
          </span>
          <br />
          <br />
          <h3>ARTICLE 11 DONNEES PERSONNELLES</h3>
          <br />
          <span>
          11.1 Règles applicables
          </span>
          <br />
          <br />
          <span>
          En utilisant le Service, vous reconnaissez et acceptez le traitement de vos données personnelles par WIN AND WIN 
          conformément à la loi applicable et aux stipulations de la Politique de Confidentialité
          </span>
          <br />
          <br />
          <span>
          Des données à caractère personnel pourront être demandées aux Participants.
          </span>
          <br />
          <br />
          <span>
          Les Participants disposent du droit d’accès, de modification, de rectification et de mise à jour de leurs 
          données à caractère personnel et du droit d’opposition à la collecte d’informations les concernant.
          </span>
          <br />
          <br />
          <span>
          Ces droits peuvent être exercés par les Participants, justifiant de leur identité, en écrivant à l’adresse suivante :
           WIN AND WIN, 66 rue de la convention 75015 Paris.
          </span>
          <br />
          <span>
          11.2 Informations complémentaires
          </span>
          <br />
          <br />
          <span>
          Les données à caractère personnel des Participants sont destinées à WIN AND WIN et le cas échéant, sous réserve de 
          l’autorisation explicite du Participant, à certains partenaires commerciaux.
          </span>
          <br />
          <br />
          <span>
          WIN AND WIN pourra procéder ou faire procéder par un tiers à l’envoi de messages à vocation commerciale concernant des
           services de WIN AND WIN identiques à ceux déjà fournis.
          </span>
          <br />
          <br />
          <span>
          Des données personnelles peuvent également être communiquées à un tiers assermenté en cas de réquisition judiciaire ou
           administrative.
          </span>
          <br />
          <br />
          <span>
          Le prestataire assurant le Service clients peut également avoir accès aux données à caractère personnel, en raison de son 
          activité de support à l’exploitation du Jeu, mais il n’est pas autorisé à les utiliser à d’autres fins.
          </span>
          <br />
          <br />
          <h3>ARTICLE 12 - RESPONSABILITES</h3>
          <br />
          <span>
          En cas de nécessité, notamment en cas de travaux d’entretien, de panne technique, d’atteinte au système de traitement 
          automatisé de données ou de difficultés provenant du réseau internet ou du réseau de téléphonie mobile, l’accès à 
          l’Application peut être suspendue provisoirement, voire arrêtée définitivement, ce que le Participant reconnaît et accepte.
          </span>
          <br />
          <br />
          <span>
          Dans le cas où le Participant n’arrive pas à se connecter et/ ou à jouer, qu’une anomalie lors de la validation de la
           combinaison provoque le non enregistrement de la combinaison, la responsabilité de WIN AND WIN ne pourra être retenue, 
           notamment si ces difficultés sont le fait, d’une mauvaise configuration du matériel informatique du Participant, d’une
            panne technique ou d’un mauvais fonctionnement des réseaux de communication.
          </span>
          <br />
          <br />
          <h3>ARTICLE 13 - AUTORISATION DE L’UTILISATION DU NOM DES GAGNANTS</h3>
          <br />
          <span>
          Chaque gagnant autorise WIN AND WIN à utiliser son prénom, la première lettre de son nom de famille et son PAYS DE 
          RÉSIDENCE sans restriction ni réserve et sans que cela lui confère une rémunération, un droit ou un avantage quelconque 
          autre que l’attribution de son lot.
          </span>
          <br />
          <br />
          <span>
          L’image de chaque gagnant pourra être utilisée à titre gracieux par WIN AND WIN, sous la forme d’une photographie ou d’une vidéo.
          </span>
          <br />
          <br />
          <h3>ARTICLE 14 - CAS DE FRAUDE</h3>
          <br />
          <span>
          Toute fraude ou tentative de fraude, manifestée par un commencement d’exécution et commise en vue de percevoir
           indûment un lot ou de participer de façon irrégulière à un tirage, fera l’objet de poursuites conformément aux dispositions, 
           y compris sur la base de l’article 313-1 et suivants du Code pénal.
          </span>
        </div>
      </div>
      <br />
      <br />
      <Footer />    </>
  );
}

export default Rules;
