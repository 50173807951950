import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/About.css";
import Xp from "../media/xp.png";
import Money from "../media/money.png";
import Coins from "../media/coins.png";

function About() {
  return (
      <>
      <Navbar />
      <div className="main__about">
      <br />
        <br />
        <br />
        <h1> QUI SOMME NOUS ?</h1>
        <br />
        <br />
        <br />
        <div className="container">
          <span>
            WAW EST LA NOUVELLE GÉNÉRATION DE JEUX DE HASARD GRATUITS, GRÂCE À NOTRE PARTENARIAT, 
            VOUS POURREZ PARTICIPER À PLUSIEURS TIRAGES AU SORT ET REMPORTER DES PRIX.
          </span>
          <br />
          <br />
          <span>
        IL NE VOUS RESTE PLUS QU'A CHOISIR VOS CARTES, JOUEZ ET GAGNER.
        RIEN DE PLUS SIMPLE, A TOI DE JOUER
          </span>
          <h1>WINNINGS</h1>
        <br />
        <br />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 align-cent__winnings">
            <div>
              <img src={Xp} alt="Xp" /> <br className="hide-mobile" />
              <br />
              <b className="font-20__winnings">EXPERIENCE LEVEL</b>
              <br />
              <br />
              <ul className="ul1__winnings">
                <li>À CHAQUE JEU, VOUS GAGNEREZ DES POINTS "WAW" </li>
              </ul>
              <ul className="ul2__winnings">
                <li>QUE VOUS POUVEZ ÉCHANGER CONTRE DES CADEAUX.</li>
              </ul>
            </div>
          </div>

          <br className="hide-desktop" />
          <br className="hide-desktop" />
          <br className="hide-desktop" />

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 align-cent__winnings">
            <div>
              <img src={Money} alt="Xp" /> <br className="hide-mobile" />
              <br />
              <b className="font-20__winnings">BILLS COLLECTED</b>
              <br />
              <br />
              <ul className="ul1__winnings">
                <li>JOUER ET GAGNER JUSQU'A </li>
              </ul>
              <ul className="ul2__winnings">
                <li>MILLE DOLLARS PAR MOIS.</li>
              </ul>
            </div>
          </div>
          <br className="hide-desktop" />
          <br className="hide-desktop" />
          <br className="hide-desktop" />

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 align-cent__winnings">
            <div>
              <img src={Coins} alt="Xp" /> <br className="hide-mobile" />
              <br />
              <b className="font-20__winnings">Coins COLLECTED</b>
              <br />
              <br />
              <ul className="ul1__winnings">
                <li>COLLECTIONNEZ LES JETONS PENDANT VOS JEUX ET TRANSFORMEZ-LES </li>
              </ul>
              <ul className="ul2__winnings">
                <li>EN ARGENT RÉEL GRÂCE À NOS TIRAGES AU SORT.</li>
              </ul>
            </div>
        </div>
      </div>
      </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default About;
