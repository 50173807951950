import Facebook from "../media/Facebook.png";
import Instagram from "../media/Instagram.png";
import Youtube from "../media/Youtube.png";
import { Link } from "react-router-dom";
import "../css/Footer.css";

function Footer() {
  return (
    <>
      <div className="footer__footer">
        <div className="align-cent__footer">
          <div className="padd-10__footer">
            <Link  to="https://www.facebook.com/profile.php?id=100090113012049">
              <img src={Facebook} alt="Facebook" />
            </Link>
          </div>
          <div className="padd-10__footer">
            <Link  to="https://www.instagram.com/winandwin.app">
              <img src={Instagram} alt="Instagram" />
            </Link>
          </div>
          <div className="padd-10__footer">
            <Link  to="/">
              <img src={Youtube} alt="Youtube" />
            </Link>
          </div>
        </div>
        <br />
        {/* for desktop */}
        <div className="align-cent__footer hide-mobile">
          <Link className="links__footer" to="/">
            <span className="item">PAGE D'ACCEUIL</span>
          </Link>

          {/* <Link className="links__footer" to="/winnings">
            <span className="item">Winnings</span>
          </Link> */}

          <Link className="links__footer" to="/rules">
            <span className="item">RÈGLES DES JEUX</span>
          </Link>

          <Link className="links__footer" to="/about">
            <span className="item">QUI SOMMES NOUS</span>
          </Link>

          <Link className="links__footer" to="/legal">
            <span className="item">MENTIONS LÉGALES</span>
          </Link>

          <Link className="links__footer" to="/contact">
            <span className="item">NOUS CONTACTER</span>
          </Link>
        </div>
        {/* for mobile */}
        <div className="hide-desktop">
          <ul className="ul__footer">
            <li>
              <Link className="links__footer" to="/">
                <span className="item">PAGE D'ACCEUIL</span>
              </Link>
            </li>
            {/* <li>
              <Link className="links__footer" to="/winnings">
                <span className="item">Winnings</span>
              </Link>
            </li> */}
            <li>
              <Link className="links__footer" to="/rules">
                <span className="item">RÈGLES DES JEUX</span>
              </Link>
            </li>
            <li>
              <Link className="links__footer" to="/about">
                <span className="item">NOUS CONTACTER</span>
              </Link>
            </li>
            <li>
              <Link className="links__footer" to="/legal">
                <span className="item">MENTIONS LÉGALES</span>
              </Link>
            </li>
            <li>
              <Link className="links__footer" to="/contact">
                <span className="item"> Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
        <br />
        <hr className="hr__footer" />
        <br />
        <div className="text-align__footer"></div>
        <div className="container"><span>

          </span></div>
        <br />
        <br />
        <div className="text-align__footer">Copyright @2023</div>
      </div>
    </>
  );
}

export default Footer;
