import Logo from "../media/Logo.png";
import AppStore from "../media/AppStore.png";
import GooglePlay from "../media/GooglePlay.png";
import "../css/Navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
  function sidebar() {
    document.querySelector("body").classList.toggle("active");
  }

  return (
    <>
      {/* for desktop */}
      <div className="padd-top-bot__navbar hide-mobile active">
        <div className="navbar__navbar">
          <div>
            <img src={Logo} alt="Logo" />
          </div>

          <div className="  hide-mobile align-txt__navbar font__navbar">
            <span> WIN AND WIN</span>
          </div>

          <div className="  hide-mobile align-txt__navbar">
            <div>
              <Link className="links__navbar " to="/">
                <img className="img__navbar" src={AppStore} alt="AppStore" />
              </Link>
            </div>
          </div>

          <div className="  hide-mobile align-txt__navbar">
            <div>
              <Link className="links__navbar" to="/">
                <img
                  className="img__navbar"
                  src={GooglePlay}
                  alt="GooglePlay"
                />
              </Link>
            </div>
          </div>

          <div className="  hide-mobile align-txt__navbar ">
            <div className="  hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/">
                PAGE D'ACCEUIL
              </Link>
            </div>
            {/* <div className=" img1__navbar hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/winnings">
                GAINS
              </Link>
            </div> */}
            <div className=" img1__navbar hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/rules">
                RÈGLES DES JEUX
              </Link>
            </div>
            <div className=" img1__navbar hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/about">
                QUI SOMMES NOUS
              </Link>
            </div>
            <div className=" img1__navbar hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/legal">
                MENTIONS LÉGALES
              </Link>
            </div>
            <div className=" img1__navbar hide-mobile align-txt__navbar">
              <Link className="links__navbar" to="/contact">
                NOUS CONTACTER
              </Link>
            </div>
          </div>
          <div className=" img1__navbar__navbar hide-desktop">
            <img className="img1__navbar__navbar" src={Logo} alt="Logo" />
          </div>
        </div>
      </div>
      {/* for mobile */}
      <div className="hide-desktop">
        <div className="wrapper">
          <div className="section">
            <div className="top_navbar">
              <div>
                <img className="img2__navbar" src={Logo} alt="Logo" />
              </div>
              <div className="chyzn" onClick={sidebar}>
                <i className="fas fa-bars"></i>
              </div>
            </div>
          </div>
          <div className="sidebar">
            <br />
            <br />
            <br />
            <ul className="ul__navbar">
              <li>
                <Link className="links__navbar" to="/">
                  <span className="item"> PAGE D'ACCEUIL</span>
                </Link>
              </li>
              {/* <li>
                <Link className="links__navbar" to="/winnings">
                  <span className="item"> GAINS</span>
                </Link>
              </li> */}
              <li>
                <Link className="links__navbar" to="/rules">
                  <span className="item">RÈGLES DES JEUX</span>
                </Link>
              </li>
              <li>
                <Link className="links__navbar" to="/about">
                  <span className="item">QUI SOMMES NOUS</span>
                </Link>
              </li>
              <li>
                <Link className="links__navbar" to="/legal">
                  <span className="item">MENTIONS LÉGALES</span>
                </Link>
              </li>
              <li>
                <Link className="links__navbar" to="/contact">
                  <span className="item">NOUS CONTACTER</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
