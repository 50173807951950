import "../css/Header.css";
import Banner from "../media/Banner.png";
import BannerMobile from "../media/Banner-mobile.png";
import AppStore from "../media/AppStore.png";
import GooglePlay from "../media/GooglePlay.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      {/* for desktop */}
      <div className="main__header hide-mobile">
        <img className="img1__header" src={Banner} alt="Banner" />
        <div className="connnect1__header">
          <Link className="links__navbar " to="/">
            <img src={GooglePlay} alt="GooglePlay" />
          </Link>
        </div>
        <div className="connnect2__header">
          <Link className="links__navbar " to="/">
            <img src={AppStore} alt="AppStore" />
          </Link>
        </div>
      </div>
      {/* for Mobile */}
      <div className="hide-desktop">
        <img className="img1__header" src={BannerMobile} alt="Banner" />
        <div className="connnect1__header  hide-mobile">
          <Link className="links__navbar " to="/">
            <img src={GooglePlay} alt="GooglePlay" />
          </Link>
        </div>
        <div className="connnect2__header  hide-mobile">
          <Link className="links__navbar " to="/">
            <img src={AppStore} alt="AppStore" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default Header;
