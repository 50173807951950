import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/Contact.css";
import axios from "axios";
import { useState } from "react";

function Contact() {

  const [data,setData] = useState({
    name:'',
    email:'',
    country:'',
    phone:'',
  })


  const onChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }


  function submit () {
    axios.get(`https://example.com/email`,{data})
  }

  return (
    <>
      <Navbar />
      <Header />
      <div className="main__contact">
        <br />
        <br />
        <br />
        <h1>CONTACT US</h1>
        <br />
        <br className="hide-mobile" />
        <br className="hide-mobile" />
        <div className="container__contact">
          <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 padd__contact">
              <div className="div__contact"> <span>Nom</span> </div>
              <input name="name" className="input__contact" onChange={onChange} type="text" placeholder="Nom" />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 padd__contact">
            <div className="div__contact"><span >Email</span></div>
            <input name="email" className="input__contact" onChange={onChange} type="email" placeholder="Email" />
            </div>
          </div>
          <br className="hide-mobile" />
          <br className="hide-mobile" />
          <br className="hide-mobile" />
          <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 padd__contact">
              <div className="div__contact"> <span>Pays</span> </div>
              <input name="country" className="input__contact" onChange={onChange} type="text" placeholder="Pays" />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 padd__contact">
            <div className="div__contact"><span >Numéro de téléphone</span></div>
            <input name="phone" className="input__contact" onChange={onChange} type="email" placeholder="Numéro de téléphone" />
            </div>
          </div>
          <br />
          <br />
          <br className="hide-mobile" />
          <br className="hide-mobile" />
          <div className="divBtn__contact">
          <button className="btn__contact"  onClick={submit}>Soumettre</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Contact;
