import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/Home.css";
import { Link } from "react-router-dom";
import WelcomeBanner from "../media/WelcomeBanner.png";
import AppStore from "../media/AppStore.png";
import GooglePlay from "../media/GooglePlay.png";

function Home() {
  return (
    <>
      <div>
        <Navbar />
        <Header />
        <div className="main__home">
          <img className="img1__home" src={WelcomeBanner} alt="Banner" />
          <div className="text__home">
      PLAY, EARN AND WIN
          </div>
          <div className="connnect1__home">
            <Link className="links__home " to="/">
               <img src={GooglePlay} alt="GooglePlay" />
            </Link>
          </div>
          <div className="connnect2__home">
            <Link className="links__home " to="/">
              <img src={AppStore} alt="AppStore" /> 
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
