import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Winnings from "./pages/Winnings";
import Rules from "./pages/Rules";
import About from "./pages/About";
import Legal from "./pages/Legal";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Politique from "./pages/Politique"
import "./App.css";

function App() {
  return (
    <BrowserRouter >
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/winnings" element={<Winnings />} /> */}
      <Route path="/rules" element={<Rules />} />
      <Route path="/about" element={<About />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/Politique" element={<Politique />} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;
