import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/Legal.css";

function Legal() {
  return (
    <>
      <Navbar />
      <div className="main__legal">
        <br />
        <br />
        <br />
        <h1>Mentions légales</h1>
        <br />
        <br />
        <br />
        <div className="container">
          <span> En vigueur au 01/03/2023</span>
          <br />
          <br />
          <span>
            Conformément aux dispositions des Articles 6-III et 19 de la Loi
            n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie
            numérique, dite L.C.E.N., il est porté à la connaissance des
            utilisateurs et visiteurs, ci-après l""Utilisateur", du site
            WINANDWIN.APP , ci-après le "Site", les présentes mentions légales.
          </span>
          <br />
          <br />
          <span>
            La connexion et la navigation sur le Site par l'Utilisateur implique
            acceptation intégrale et sans réserve des présentes mentions
            légales.
          </span>

          <br />
          <br />
          <span>
            Ces dernières sont accessibles sur le Site à la rubrique « Mentions
            légales »
          </span>
          <br />
          <br />
          <h3>ARTICLE 1 - L'EDITEUR</h3>
          <br />
        
          <span>
            Le site winandwin.app est la propriété exclusive de SASU Isoapps,
            qui l'édite.
          </span>
          <br />
          <br />
          <span>ISOAPPS</span>
          <br />
          <br />
          <span>SASU au capital de 100 €</span>
          <br />
          <br />
          <span>66 Rue de la Convention 75015 Paris</span>
          <br />
          <br />
          <span>
            Immatriculée au Registre du Commerce et des Sociétés de Paris 948
            782 420
          </span>
          <br />
          <br />
          <span>sous le numéro 94878242000012</span>
          <br />
          <br />
          <span>Numéro TVA intracommunautaire : FR30948782420</span>
          <br />
          <br />
          <span>Adresse de courrier électronique : contact@isoapps.fr</span>
          <br />
          <br />
          <span>Le Directeur de la publication est Salame Mounif</span>
          <br />
          <br />
          <span>
            Contactez le responsable de la publication : contact@winanwin.app
          </span>
          <br />
          <br />
          <span>ci-après l'"Editeur".</span>
          <br />
          <br />
          <h3>ARTICLE 2 - L'HEBERGEUR</h3>
          <br />
          <span>L'hébergeur du Site est la société</span>
          <br />
          <br />
          <span>Amazon web services</span>
          <br />
          <br />
          <span>31 Pl. des Corolles,</span>
          <br />
          <br />
          <span>92400 Courbevoie, France</span>
          <br />
          <br /> <br />
          <h3>ARTICLE 3 - ACCES AU SITE</h3>
          <br />
          <span>
            Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de
            force majeure, interruption programmée ou non et pouvant découlant
            d'une nécessité de maintenance.
          </span>
          <br />
          <br />
          <span>
            En cas de modification, interruption ou suspension du Site,
            l'Editeur ne saurait être tenu responsable.
          </span>
          <br />
          <br />
          <h3>ARTICLE 4 - COLLECTE DES DONNÉES</h3>
          <br />
          <span>
            Le Site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la loi n°78-17 du 6 janvier 1978 relative à
            l'informatique, aux fichiers et aux libertés.
          </span>
          <br />
          <br />
          <span>
            En vertu de la loi Informatique et Libertés, en date du 6 janvier
            1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de
            suppression et d'opposition de ses données personnelles.
            L'Utilisateur exerce ce droit :
          </span>
          <br />
          <br />
          <span>· via son espace personnel ;</span>
          <br />
          <br />
          <span>
            Toute utilisation, reproduction, diffusion, commercialisation,
            modification de toute ou partie du Site, sans autorisation de
            l'Editeur est prohibée et pourra entraînée des actions et poursuites
            judiciaires telles que notamment prévues par le Code de la propriété
            intellectuelle et le Code civil.
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Legal;
